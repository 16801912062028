import { toast } from "react-hot-toast";
import axios from "axios";
import { setUserPay } from "../Redux/userActions";
// import { SaveExcel } from "./SaveExcel";
function redirectToPayment(url) {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
}

export async function initiatePayment(
  cartItems,
  dataToSubmit,
  deliveryCharge,
  totalAmount,
  user,
  setFormData,
  token,
  navigate,
  dispatch,
  abortController
) {
  const toastId = toast.loading("Loading...");

  try {
    dispatch(setUserPay(dataToSubmit));
    const total = totalAmount;
    // Create order and initiate payment
    const orderResponse = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/payonline`,
      { cartItems, dataToSubmit, user, total },
      {
        headers: { Authorization: `Bearer ${token}` },
        signal: abortController.signal,
      }
    );

    if (!orderResponse.data.success) {
      throw new Error(orderResponse.data.message || "Failed to create order.");
    }
    const paymentUrl = orderResponse.data.redirectUrl;
    const orderId = orderResponse.data.paymentId;
    redirectToPayment(paymentUrl);

    const paymentVerificationResult = await verifyPayment(orderId, token);
    if (paymentVerificationResult.success) {
      // await SaveExcel(dataToSubmit, orderId);
      // navigate("/dashboard");
      return "success";
    } else {
      toast.error("Payment verification failed.");
      return "nosuccess";
    }
  } catch (error) {
    toast.error(
      "Could not make Payment: " +
        (error.response?.data?.message || error.message)
    );
    return { success: false };
  } finally {
    toast.dismiss(toastId);
  }
}

async function verifyPayment(orderId, token, dataToSubmit) {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/paymentverify?id=${orderId}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    return { success: true };
  } catch (error) {
    return { success: false };
  }
}
