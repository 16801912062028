import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const HeroSection = ({ mainRef }) => {
  const language = useSelector((state) => state.language.language) || 0;

  const [fontFamily, setFontFamily] = useState("Plus Jakarta Sans");
  const head = ["Prasad", "प्रसाद"];
  const quote = [
    "Choose with devotion, offered with faith",
    "श्रद्धा से चुनें, आस्था से अर्पित करें",
  ];
  const heading = ["Jai Shree Shyam", "जय श्री श्याम"];

  const [fontFamilyHeading, setFontFamilyHeading] =
    useState("Plus Jakarta Sans");

  useEffect(() => {
    setFontFamilyHeading(language === 0 ? "Plus Jakarta Sans" : "Baloo Bhai");
  }, [language]);
  const content = [
    "At ParamBhog, we are devoted to helping you connect with the divine presence of Khatushyamji. Now, you can offer prasad to Khatu Shyam Ji from anywhere anytime, we humbly offer prasad on your behalf. You simply let us know your desired offering, and we procure the prasad with care. It is then reverently presented at the holy feet of Khatushyamji. Also for transparency, we share a video proof with you. Once blessed, the prasad is lovingly packaged and sent to you, carrying the divine blessings to your home.",
    "परमभोग में हम आपको खाटू श्याम जी की दिव्य उपस्थिति से जोड़ने में समर्पित हैं। आप कभी भी ओर कहीं से भी अब खाटू नरेश को प्रसाद अर्पित कर सकते हैं आप अपने पसंदीदा प्रसाद का चयन करे, और हम आपके चयन किए हुए प्रसाद को श्रद्धा के साथ, खाटू श्याम जी के चरणों में अर्पित करते हैं। आशीर्वादित होने के बाद, ध्यानपूर्वक वह प्रसाद आपको भेजा जाता है, साथ ही आप प्रसाद अर्पित करने की वीडियो भी देख सकते है। तो आज ही परम भोग के माध्यम से खाटू नरेश को प्रसाद अर्पित करे।",
  ];
  const videoContent = [
    `Know how we offer "Prasad"`,
    `"प्रसाद" अर्पित करने की विधि को जाने।`,
  ];

  const scrollToMain = () => {
    // handleClick("User", "Checking Prasadam", "Home", 1);
    if (mainRef.current) {
      const elementPosition =
        mainRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition =
        elementPosition -
        1.4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="md:mt-12 mt-1 md:block px-2 md:px-12 md:rounded-lg border-t-2 border-white md:min-h-[94vh]">
      <div className="md:px-10 md:mt-10 px-2 bg-gray-50">
        <div className="md:text-5xl text-3xl font-semibold " ref={mainRef}>
          <h1
            className="md:text-6xl text-3xl md:h-20 h-13 pt-3 text-transparent bg-clip-text font-semibold"
            style={{
              background: "radial-gradient(circle, #FDC830, #F37335)",
              WebkitBackgroundClip: "text",
              fontFamily: fontFamilyHeading,
            }}
          >
            <span className="hidden md:block">{heading[language]}</span>
          </h1>
        </div>
        <div></div>
        <div
          className="block  text-4xl md:hidden"
          style={{
            fontFamily: "Montserrat, serif",
            fontOpticalSizing: "auto",
            fontWeight: 600,
            fontStyle: "normal",
          }}
        >
          {head[language]}
        </div>
        <div className="md:text-2xl  text-base italic pb-2 quote mt-0 md:block  items-center justify-center ">
          {/* <FontAwesomeIcon
            icon={faQuoteLeft}
            className="text-orange-500 mr-2"
          /> */}
          <span className="quote-text ml-[0.199rem] ">{quote[language]}</span>
          {/* <FontAwesomeIcon
            icon={faQuoteRight}
            className="text-orange-500 ml-2"
          /> */}
        </div>
      </div>

      <div className="md:flex hidden px-2 md:px-12 md:flex-row md:gap-28 gap-8  to-orange-200 p-8 ">
        <div
          className="md:text-[1.5rem] pt-1 md:pt-4 text-[0.9rem] md:leading-[1.8rem] leading-[1.2rem] font-medium text-justify"
          style={{ fontFamily }}
        >
          {content[language]}
        </div>
      </div>
      <div className="hidden  md:flex md:justify-between md:gap-4">
        <button
          onClick={scrollToMain}
          className=" mt-0 md:p-4 ml-12 p-3 text-white md:text-lg text-sm bg-orange-500 rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500 border hover:border-orange-500 hover:shadow-lg hover:rounded-full hover:border-2"
        >
          {language === 0 ? "Offer Prasadam" : "प्रसाद अर्पित करें"}
        </button>

        {/* <button
          onClick={youtubeVideo}
          className="md:mt-16 mt-10  md:p-4 p-3 text-white md:text-lg text-sm bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
        >
          Youtube : Batch {date}
        </button> */}
      </div>
      {/* <div className="md:flex hidden px-2 md:px-12 md:flex-row md:gap-28 gap-8 ">
          <div
            className="md:text-[1.5rem] pt-1 md:pt-4 text-[0.9rem] md:leading-[1.8rem] leading-[1.2rem]  font-medium text-justify "
            style={{ fontFamily }}
          >
            {content[language]}
          </div>

          <div className="flex md:flex-col flex-col-reverse md:gap-0 gap-4">
            <iframe
              src="https://www.youtube.com/embed/RIzTuwBEEYY?si=S2whCKRQHGwyn46i"
              title="YouTube video player"
              frameborder="0"
              className="rounded-3xl w-[90vw] md:mt-4 h-[40vh] mx-auto   md:w-[560px] md:h-[315px]"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <h1 className="md:text-4xl text-sm md:pt-4 pt-0 font-semibold text-center text-orange-500">
              {videoContent[language]}
            </h1>
          </div>
        </div> */}

      {/* <div
          className={`md:text-[1.5rem] ${language ===1 ?'md:leading-[2.5rem] leading-[1.2rem]' : 'md:leading-[1.8rem] leading-[1.2rem]'} pt-1 md:pt-4 text-[0.9rem]  font-medium text-justify`}
          style={{ fontFamily }}
        >
          {isMobile && !showFullText
            ? getFirst50Words(content[language])
            : content[language]}

          {isMobile &&
            !showFullText &&
            content[language].split(" ").length > 50 && (
              <button
                onClick={toggleText}
                className="text-blue-500 hover:underline text-[0.9rem] leading-[1.2rem] "
              >
                Show more
              </button>
            )}

          {isMobile && showFullText && (
            <button
              onClick={toggleText}
              className="text-blue-500 hover:underline"
            >
              Show less
            </button>
          )}
        </div> */}
    </div>
  );
};

export default HeroSection;
