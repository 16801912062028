import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import toast from "react-hot-toast";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../Redux/userActions";
const countryCodes = [
  { code: "+91", name: "India" },
  { code: "+1", name: "USA" },
];
const PhoneVerification = ({ handleClick }) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+91");
  useEffect(() => {
    let interval;
    if (timer > 0 && showOtpInput) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
    }
    return () => clearInterval(interval);
  }, [timer, showOtpInput]);

  const initializeRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: () => {
          // toast.success("reCAPTCHA verified");
        },
        defaultCountry: "IN",
      },
      auth
    );
  };

  const onSignIn = (e) => {
    e.preventDefault();
    // if (mobile.length !== 10) {
    //   toast.error("Please enter a valid 10-digit mobile number.");
    //   return;
    // }

    setIsLoading(true);
    initializeRecaptcha();
    const phoneNumber = countryCode + mobile;
    // const phoneNumber = "+91"  + mobile;
    const appVerifier = window.recaptchaVerifier;
    handleClick("User", "Contact No Login", "Login Form", 1);
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOtpInput(true);
        setTimer(60);
        setIsResendEnabled(false);
        toast.success("OTP sent successfully!");
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error sending OTP. Please try again.");
        setIsLoading(false);
      });
  };

  const handleResend = () => {
    if (isResendEnabled) {
      window.recaptchaVerifier.clear();
      initializeRecaptcha();
      onSignIn(new Event("resend"));
    }
  };
  const [name, setName] = useState(null);

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const onSubmitOtp = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }
    const loadingToast = toast.loading("Server is slow, please wait...");
    if (window.confirmationResult) {
      setIsLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (result) => {
          const user = result.user;
          const uid = await user.getIdToken();
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/phone`,
            { phoneNumber: countryCode + "-" + mobile, uid, name }
            // { phoneNumber: "+91-" + mobile, uid }
          );
          if (response.data.success) {
            const { user, token } = response.data;
            dispatch(setUser(user, token));
            toast.dismiss(loadingToast);
            toast.success("OTP verified successfully!", {
              autoClose: 1000,
            });
            setTimeout(() => {
              navigate("/cart");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 500);
          } else {
            toast.dismiss(loadingToast);
            toast.error("OTP verification failed. Please try again.", {
              autoClose: 1000,
            });
          }

          setIsLoading(false);
        })
        .catch((error) => {
          toast.dismiss(loadingToast);
          toast.error("Invalid OTP. Please try again.", {
            autoClose: 2000,
          });
          setIsLoading(false);
        });
    } else {
      toast.dismiss(loadingToast);

      toast.error("OTP verification failed. Please try again.", {
        autoClose: 1000,
      });
      setIsLoading(false);
    }
  };

  // const heading = ["Jai Shree Shyam", "जय श्री श्याम"];
  // const language = useSelector((state) => state.language.language);

  return (
    <div className="w-full h-full flex justify-center ">
      <div className="relative w-full max-w-md">
        <div className="mb-4 mt-0">
          <img
            src="https://res.cloudinary.com/dtit4w15r/image/upload/q_auto,f_webp,w_1200,h_800/v1735059645/Images/hzptunqkrfglpcmqhelj.png"
            alt="Logo"
            className="w-full h-[100vh]"
            loading="lazy"
            srcSet="https://res.cloudinary.com/dtit4w15r/image/upload/q_auto,f_webp,w_600,h_400/v1735059645/Images/hzptunqkrfglpcmqhelj.png 600w, 
            https://res.cloudinary.com/dtit4w15r/image/upload/q_auto,f_webp,w_1200,h_800/v1735059645/Images/hzptunqkrfglpcmqhelj.png 1200w, 
            https://res.cloudinary.com/dtit4w15r/image/upload/q_auto,f_webp,w_2000,h_1333/v1735059645/Images/hzptunqkrfglpcmqhelj.png 2000w"
            sizes="(max-width: 600px) 600px, (max-width: 1024px) 1200px, 2000px"
          />
        </div>

        <div className="bg-transparent backdrop-blur-sm absolute w-full mx-auto bottom-0 left-1/2 transform -translate-x-1/2 py-3 pb-[10vh] px-6">
          <h1 className="text-3xl font-bold text-center">Login</h1>

          <div className="flex mx-6 items-center border-2 bg-transparent mt-4 border-black rounded-2xl px-2">
            <input
              type="text"
              name="mobile"
              placeholder="Full Name"
              required
              value={name}
              onChange={(e) => handleChange(e)}
              className="w-full text-xl  py-3 border-none focus:outline-none bg-transparent placeholder:text-gray-500"
            />
          </div>
          {!showOtpInput ? (
            <>
              <form onSubmit={onSignIn} className="p-6 w-full max-w-md">
                <div className="hidden" id="sign-in-button"></div>
                <div className="flex items-center border-2 bg-transparent border-black rounded-2xl px-2">
                  {/* <span className="px-3 text-xl py-3 border-r-4 border-black">
                    +91
                  </span> */}
                  <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    className="text-xl py-3 border-r-2 px-1 border-black bg-transparent focus:outline-none"
                  >
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile Number"
                    required
                    value={mobile}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, "");
                      if (newValue.length <= 10) {
                        setMobile(newValue);
                      }
                    }}
                    className="w-full text-xl px-3 py-3 border-none focus:outline-none bg-transparent placeholder:text-gray-500"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full mt-4 bg-blue-500 text-white hover:text-blue-600 hover:bg-white focus:outline-none focus:ring-2 font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 focus:ring-blue-500 hover:border-blue-500 hover:shadow-lg p-3 md:text-xl text-sm rounded-2xl hover:rounded-xl hover:border-2"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending OTP..." : "Send OTP"}
                </button>
              </form>
            </>
          ) : (
            <>
              <h2 className="text-xl font-semibold text-black mt-4">
                Enter OTP
              </h2>
              <form onSubmit={onSubmitOtp} className="mt-2">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  isInputNum
                  shouldAutoFocus
                  renderSeparator={<span>-</span>}
                  containerStyle={{ display: "flex", justifyContent: "center" }}
                  inputStyle={{ marginRight: "0.5rem" }}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="w-[3rem] sm:w-[3.5rem] md:w-[4rem] lg:w-[4rem] h-12 mx-2 text-2xl text-center border-black bg-transparent border-b-4 placeholder:text-gray-400 focus:outline-none"
                    />
                  )}
                />
                <div className="">
                  <div className="mt-2 flex justify-end">
                    {isResendEnabled ? (
                      <button
                        onClick={handleResend}
                        className="text-base text-blue-500 py-2 px-4 rounded-md hover:text-blue-600 transition-all duration-200 ease-in-out"
                      >
                        Resend OTP
                      </button>
                    ) : (
                      <p className="rounded-md py-2 px-4 text-blue-500">
                        Resend OTP in {timer} second{timer > 1 ? "s" : ""}
                      </p>
                    )}
                  </div>
                  <div className="mb-2 mt-2 flex justify-center">
                    <button
                      type="submit"
                      className="w-full p-3 text-sm md:texl-xl bg-orange-500 text-white focus:ring-orange-500 hover:text-orange-600 hover:bg-white hover:border focus:outline-none focus:ring-2 rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:border-orange-500 hover:shadow-lg"
                      disabled={isLoading}
                    >
                      {isLoading ? "Verifying OTP..." : "Continue"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
