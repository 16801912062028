import React from "react";
import { useSelector } from "react-redux";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const PaymentItem = ({ payment, data }) => {
  const language = useSelector((state) => state.language.language);

  const cartItems = payment.cartItems || [];

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div key={payment._id.$oid} className="mb-4 border p-2 rounded">
      <div className="flex justify-between mr-10">
        <h3 className="font-bold text-md mb-2">Order Items:</h3>
        <p className="mb-2">Total: ₹{payment.total.$numberDecimal} </p>
      </div>
      <ul className="list-disc list-inside">
        {cartItems.map((item, index) => {
          const product = data.find((obj) => obj.id === item.id);

          if (!product) {
            return (
              <li key={index} className="text-red-500">
                Product not found
              </li>
            );
          }
          return (
            <li key={index} className="flex text-base justify-between mb-2">
              <div className="w-1/4">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-auto h-20 object-cover rounded-xl  "
                />
              </div>
              <div className="w-1/3 ml-3">
                <p className="font-semibold">
                  {language === 0 ? product.title : product.hTitle}
                </p>
                <p className="text-gray-500 mt-4">
                  {formatDate(payment.updatedAt)}
                </p>
              </div>
              <div className="w-1/3 ml-3">
                <p>Price: ₹{item.price}</p>
                <p className="text-gray-500  mt-4">Quantity: {item.quantity}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PaymentItem;
// import React from "react";
// import { useSelector } from "react-redux";

// const formatDate = (dateString) => {
//   const options = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//   };
//   return new Date(dateString).toLocaleDateString(undefined, options);
// };

// const PaymentItem = ({ payment }) => {
//   const language = useSelector((state) => state.language.language);
//   const data = useSelector((state) => state.data.data);
//   const cartItems = payment.cartItems || [];

//   return (
//     <div key={payment._id.$oid} className="mb-4 border p-2 rounded">
//       <p className="mb-2">Total: ₹{payment.total.$numberDecimal}</p>
//       <p className="mb-2">Payment: {payment.status !== 'online' ? 'Online Payment' : 'Cash On Delivery'}</p>
//       <p className="mb-2">Ordered At: {formatDate(payment.updatedAt)}</p>

//       <h3 className="font-bold text-md mb-2">Order Items:</h3>
//       <ul className="list-disc list-inside">
//         {cartItems.map((item, index) => {
//           const product = data.find((p) => p.id === item.id);
//           if (!product) {
//             return (
//               <li key={index} className="text-red-500">
//                 Product not found
//               </li>
//             );
//           }
//           return (
//             <li key={index} className="flex items-center mb-2">
//               <div className="w-1/4">
//                 <img src={product.image} alt={product.title} className="w-full h-auto object-cover rounded" />
//               </div>
//               <div className="w-3/4 pl-4">
//                 <p className="font-semibold">
//                   {language === 0 ? product.title : product.hTitle}
//                 </p>
//                 <p>Price: ₹{item.price}</p>
//                 <p>Quantity: {item.quantity}</p>
//               </div>
//             </li>
//           );
//         })}
//       </ul>
//     </div>
//   );
// };

// export default PaymentItem;
