import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { clearUser } from "../Redux/userActions";
import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";

export default function DeleteAccount() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteAccount = async () => {
    const loadingToastId = toast.loading("Deleting account...");

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/user/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Account deleted successfully.", { id: loadingToastId });
        dispatch(clearUser());
        navigate("/login");
      } else {
        toast.error(response.data.message || "Failed to delete account.", {
          id: loadingToastId,
        });
      }
    } catch (error) {
      toast.error("An error occurred while deleting the account.", {
        id: loadingToastId,
      });
    }
  };

  return (
    <>
      <div className="my-10 flex flex-row gap-x-5 rounded-md border-[1px] border-youtube bg-white py-5 ">
        <div className="flex aspect-square h-14 w-14 items-center justify-center rounded-full bg-white">
          <FiTrash2 className="text-3xl text-youtube" />
        </div>
        <div className="flex flex-col space-y-2">
          <h2 className="text-lg font-semibold text-richblack-5">
            Delete Account
          </h2>
          <div className="w-4/5 text-pink-25 text">
            <p>Would you like to delete your account?</p>
          </div>
          <button
            type="button"
            className="w-fit cursor-pointer italic text-youtube"
            onClick={() => setIsModalOpen(true)}
          >
            I want to delete my account.
          </button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={() => {
          handleDeleteAccount();
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
        message="Are you sure you want to delete your account? This action is permanent."
      />
    </>
  );
}
