import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../Redux/userActions";
import toast from "react-hot-toast";

const Address = ({
  userAddress,
  setUserAddress,
  index,
  language,
  setFormData,
  formData,
  isShow,
  setShow,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);

  const [errors, setErrors] = useState({
    contactNo: "",
    pincode: "",
  });

  const fetchPincodeData = async (value) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${Number(value)}`
      );
      const postOffices = response.data[0]?.PostOffice;
      if (postOffices && postOffices.length > 0) {
        const district = postOffices[0].District;
        const state = postOffices[0].State;
        setUserAddress((prevAddresses) => {
          const updatedAddresses = [...prevAddresses];
          updatedAddresses[index] = {
            ...updatedAddresses[index],
            district,
            state,
          };
          return updatedAddresses;
        });
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setUserAddress((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index] = { ...updatedAddresses[index], [field]: value };
      return updatedAddresses;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({ contactNo: "", pincode: "" });

    const contactNo = userAddress[index]?.contactNo || "";
    const isContactNoValid = /^[0-9]{10}$/.test(contactNo);
    const pincode = userAddress[index]?.pincode || "";
    const isPincodeValid = /^[0-9]{6}$/.test(pincode);
    if (!isContactNoValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNo: "Please enter a valid 10-digit mobile number.",
      }));
      toast.error("Invalid mobile number!", { duration: 1000 });
      return;
    }

    if (!isPincodeValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pincode: "Please enter a valid 6-digit pincode.",
      }));
      toast.error("Invalid pincode!", { duration: 1000 });
      return;
    }

    setFormData({
      ...formData,
      contactNo,
      pincode,
      state: userAddress[index]?.state || "",
      district: userAddress[index]?.district || "",
      address: userAddress[index]?.address || "",
    });

    setShow((prevShow) => {
      const updatedShow = [...prevShow];
      updatedShow[index] = true;
      return updatedShow;
    });
    dispatch(setUser({ ...user, userAddress: [...userAddress] }, token));
    toast.success("Address successfully submitted!", { duration: 1000 });
  };

  return (
    <div>
      {isShow ? (
        <div className="address-summary"></div>
      ) : (
        <div className="max-w-full sm:max-w-md mx-auto p-4 mt-3 shadow-[0_8px_30px_rgb(0,0,0,0.12)] rounded-3xl">
          <form onSubmit={handleSubmit} className="">
            <div className="grid gap-4">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                required
                value={userAddress[index]?.name || ""}
                onChange={(e) => handleInputChange(e, "name")}
                className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
              />
              <input
                type="number"
                min="0"
                name="contactNo"
                placeholder="Mobile Number"
                value={userAddress[index]?.contactNo || ""}
                onChange={(e) => handleInputChange(e, "contactNo")}
                className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
              />
              {errors.contactNo && (
                <div className="error text-red-500">{errors.contactNo}</div>
              )}
            </div>

            <div className="pb-4 mt-4">
              <div className="grid gap-4">
                <input
                  type="number"
                  name="pincode"
                  placeholder="Pincode"
                  value={userAddress[index]?.pincode || ""}
                  onChange={(e) => {
                    handleInputChange(e, "pincode");
                    fetchPincodeData(e.target.value);
                  }}
                  className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
                  required
                />
                {errors.pincode && (
                  <div className="error text-red-500">{errors.pincode}</div>
                )}
                <input
                  type="text"
                  name="state"
                  required
                  placeholder="State"
                  value={userAddress[index]?.state || ""}
                  onChange={(e) => handleInputChange(e, "state")}
                  className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
                />
                <input
                  type="text"
                  name="district"
                  required
                  placeholder="District"
                  value={userAddress[index]?.district || ""}
                  onChange={(e) => handleInputChange(e, "district")}
                  className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
                />
                <input
                  type="text"
                  name="address"
                  required
                  placeholder="Flat, House no., Building, Company, Apartment"
                  value={userAddress[index]?.address || ""}
                  onChange={(e) => handleInputChange(e, "address")}
                  className="p-2 border-2 border-black rounded-xl outline-orange-500 placeholder:text-sm md:placeholder:text-base"
                />
              </div>
            </div>

            <div className="w-full flex justify-center items-center">
              <button
                type="submit"
                className="px-5 py-2 md:text-xl text-lg text-white bg-orange-500 rounded-2xl font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500 hover:border-orange-500 hover:shadow-lg hover:rounded-xl hover:border-2"
                disabled={
                  !userAddress[index]?.name ||
                  !userAddress[index]?.address ||
                  !userAddress[index]?.state ||
                  !userAddress[index]?.district ||
                  !userAddress[index]?.contactNo ||
                  !userAddress[index]?.pincode
                }
              >
                {language === 0 ? "Submit" : "सबमिट करें"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Address;
