import { SET_DATA, CLEAR_DATA } from './dataActions';

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
  // console.log(`Cookie set: ${name}=${value}; expires=${expires}; path=/`);
};

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  const cookieValue = match ? decodeURIComponent(match[2]) : null;
  // console.log(`Cookie retrieved: ${cookieValue}`);
  return cookieValue;
};

let initialState = {
  data: [],
};

const cookieValue = getCookie('paramData');

if (cookieValue) {
  try {
    const parsedCookieValue = JSON.parse(cookieValue);
    initialState.data = parsedCookieValue.data || [];
  } catch (error) {
    // console.error('Failed to parse data items from cookie:', error);
  }
}

const setDataCookie = (dataItems) => {
  const cookieValue = JSON.stringify({ data: dataItems, });
  setCookie('paramData', cookieValue, 7);
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      const { data } = action.payload;
      if (Array.isArray(data)) {
        // console.log('Setting data:', data);
        setDataCookie(data);
        // const cookieValue = getCookie('paramData');
        // console.log('Retrieved cookie value:', cookieValue);
        return { data };
      } else {
        // console.error("SET_DATA action payload is missing 'data' property or is not an array");
        return state;
      }

    case CLEAR_DATA:
      setDataCookie([]);
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default dataReducer;