import toast from "react-hot-toast";

export const showToast = (message) => {
  toast.error(message, {
    duration: 1500,
    position: "top-right",
    style: {
      backgroundColor: "#f44336",
      color: "#fff",
      padding: "16px",
      borderRadius: "8px",
    },
  });
};
 