import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentItem from "./PaymentItem";
import { clearUser } from "../Redux/userActions";
import data from "../data.json";
import DeleteAccount from "./DeleteAccount";
import DownloadDivAsPNG from "./DownloadDivAsPNG";
const UserProfile = ({ handleClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const isMounted = useRef(false);
  const [paymentData, setPaymentData] = useState([]);
  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    if (!user) {
      navigate("/login");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const fetchPaymentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPaymentData(response.data);
      } catch (error) {}
    };
    fetchPaymentData();

    handleClick("User", "Checking Dashboard", "Dashboard", 1);
  }, [user, token, navigate, handleClick]);
  const location = useLocation();
  const [name, setName] = useState(location.state?.name || null);
  const logout = () => {
    dispatch(clearUser());
    navigate("/");
  };

  if (!user) {
    return null;
  }

  return (
    <div className="p-4 bg-gray-100 mt-10 md:mt-12 rounded shadow-lg max-w-md mx-auto">
      {name && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <div className="bg-transparent p-8 max-w-lg w-full relative">
            <button
              className="absolute rounded-full top-10 bg-gray-400 px-2 right-12 z-20 text-xl"
              onClick={() => setName(null)}
            >
              &times;
            </button>
            <DownloadDivAsPNG name={name} setName={setName} />
          </div>
        </div>
      )}
      <h2 className="text-xl font-semibold mb-2">User Profile</h2>
      <p>
        <strong>Name:</strong> {user?.userAddress?.[0].name}
      </p>
      {/* <p>
        <strong>Email:</strong> {user.email}
      </p> */}
      <strong>Pincode:</strong> {user?.userAddress?.[0]?.pincode}
      <p>
        <strong>State:</strong> {user?.userAddress?.[0].state}
      </p>
      <p>
        <strong>District:</strong> {user?.userAddress?.[0].district}
      </p>
      <p>
        <strong>Full Address:</strong> {user?.userAddress?.[0].address}
      </p>
      <p>
        <strong>Contact No:</strong> +91- {user.contactNo}
      </p>
      <div className="flex justify-end items-end w-full">
        <button
          onClick={logout}
          className="p-3 px-8 text-white bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
        >
          Logout
        </button>
      </div>
      <div className="p-4 bg-gray-100 mt-4 rounded shadow-lg max-w-md mx-auto">
        <h2 className="text-lg font-semibold mb-2">Your Orders</h2>
        {paymentData.length > 0 ? (
          paymentData.map((payment) => (
            <PaymentItem key={payment._id} payment={payment} data={data} />
          ))
        ) : (
          <p>No payment data available.</p>
        )}
      </div>
      <DeleteAccount />
    </div>
  );
};

export default UserProfile;
