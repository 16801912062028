import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import circle from "./Images/circle.svg";
// import rcircle from "./Images/rcircle.svg";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart } from "../Redux/cartActions";
import { PayCod } from "../service/PayCod";
import { initiatePayment } from "../service/PayOnline";
import axios from "axios";
import "./style.css";
import Address from "./Address";
let abortController;

const PaymentPage = ({ handleClick }) => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const cartItems = useSelector((state) => state.cart.items || []);
  if (!user) {
    navigate("/login");
  }
  const location = useLocation();
  const [AddMannat, setAddMannat] = useState(false);
  const [mannat, setMannat] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [del, setDel] = useState(104);
  const [ced, setCed] = useState(52);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (!location.state) {
      navigate("/cart");
    }
    const { addMannat, totalWeight, totalAmount } = location.state || {
      addMannat: false,
    };
    if (totalAmount <= 500) setDeliveryCharge(72);
    else setDeliveryCharge(0);

    setTotal(totalAmount);
    setTotalWeight(totalWeight);
    setAddMannat(addMannat);
    setMannat(addMannat ? 24 : 0);
    const fetchData = async () => {
      const dataToSend = {
        data: {
          from_pincode: "332404",
          to_pincode:
            userAddress[selectedIndex]?.pincode?.toString() || "226001",
          shipping_length_cms: "22",
          shipping_width_cms: "12",
          shipping_height_cms: "12",
          shipping_weight_kg: (totalWeight / 1000).toString(),
          order_type: "forward",
          payment_method: "prepaid",
          product_mrp: totalAmount.toString(),
          access_token: process.env.REACT_APP_ACCESS_TOKEN,
          secret_key: process.env.REACT_APP_SECRET_KEY,
        },
      };

      try {
        const response = await axios.post(
          "https://my.ithinklogistics.com/api_v3/rate/check.json",
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const final = response?.data?.data?.find(
          (x) => x.logistic_name === "Delhivery"
        );
        if (final) final.rate = Math.floor(final?.rate);
        setDel(totalWeight > 500 ? final?.rate : Math.floor(final?.rate / 2));
        setCed(totalWeight < 500 ? 0 : Math.floor(final?.rate / 2));
      } catch (error) {}
    };

    fetchData();
  }, [location, selectedIndex, user.userAddress]);
  // const addTotal = () => {
  //   let tot = 0;
  //   // tw = 0;
  //   cartItems.forEach((item) => {
  //     tot += item.price * item.quantity;
  //     // tw += item.netWeight * item.price * item.quantity;
  //   });
  //   setTotal(tot);
  //   if (tot <= 500) setDeliveryCharge(72);
  //   else setDeliveryCharge(0);
  //   // setTotalWeight(tw);
  // };
  const [pay, setPay] = useState(false);

  // const [totalWeight, setTotalWeight] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [codCharge, setCodCharge] = useState(0);
  const [orderPlaced, setOrderPlaced] = useState(false);
  // useEffect(() => {
  //   addTotal();
  // }, [cartItems]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name || "",
    email: "",
    contactNo: user.contactNo || "",
    pincode: user.userAddress[0]?.pincode || "",
    state: user.userAddress[0]?.state || "",
    district: user.userAddress[0]?.district || "",
    address: user.userAddress[0]?.address || "",
    paymentMethod: "",
    paymentId: "",
    deliveryType: "",
  });
  // const isMobile = window.innerWidth < 768;
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  // let tw = 0;

  // const scrollToHead = () => {
  //   if (headRef.current) {
  //     headRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleRemove = (item) => {
    item.quantity = 0;
    dispatch(addToCart({ ...item, quantity: 0 }));
  };
  const [selectedIndexDel, setSelectedIndexDel] = useState(0);
  const [selectedOptionDel, setSelectedOptionDel] = useState(0);
  const handleRadioChange = (value, index) => {
    setSelectedOptionDel(value);
    setSelectedIndexDel(index);
  };

  const formatDecimals = (num) => parseFloat(num).toFixed(0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "paymentMethod") {
      setCodCharge(value === "COD" ? 43 : 0);
    } else if (name === "contactNo") {
      if (Number(value) > 9999999999) {
        toast.error("Mobile number must be 10 digits.");
      }
    }
  };

  const [userAddress, setUserAddress] = useState(user.userAddress || []);
  const [show, setShow] = useState([
    user.userAddress.length > 0 ? true : false,
    true,
  ]);

  const toggleSelectedAddress = async (index) => {
    setSelectedIndex(index);
    setFormData({
      ...formData,
      contactNo: userAddress[index]?.contactNo || "",
      pincode: userAddress[index]?.pincode || "",
      state: userAddress[index]?.state || "",
      district: userAddress[index]?.district || "",
      address: userAddress[index]?.address || "",
      name: userAddress[index]?.name || "",
    });
  };

  const onEditAddress = (index) => {
    setShow((prevShow) => {
      const updatedShow = [...prevShow];
      updatedShow[index] = false;
      return updatedShow;
    });
  };
  const onAddNewAddress = () => {
    if (userAddress.length >= 2) return;
    setSelectedIndex(1);
    setShow((prevShow) => {
      const updatedShow = [...prevShow];
      updatedShow[1] = false;
      return updatedShow;
    });
    setUserAddress((prevAddresses) => [
      ...prevAddresses,
      {
        name: user.name,
        contactNo: user.contactNo,
        pincode: "",
        state: "",
        district: "",
        address: "",
      },
    ]);
  };
  const radioOptions = [
    {
      value: "std",
      title: "Standard Delivery",
      description: [
        `Receive full ${totalWeight} grams of prasad at your doorstep.`,
        "Video proof available.",
      ],
      hdescription: [
        `आपको ${totalWeight} ग्राम प्रसाद आपके घर पर डिलीवर किया जाएगा।`,
        "वीडियो प्रमाण उपलब्ध है।",
      ],
    },
    {
      value: "ced",
      title: "Cost-Effective Delivery",
      description: [
        "400 grams of prasad delivered to you.",
        "The rest will be shared with other devotees at Khatu Shyam Ji's temple.",
        "Video proof available.",
      ],
      hdescription: [
        "400 ग्राम प्रसाद आपको भेजा जाएगा।",
        "बाकी का प्रसाद खाटू श्याम जी के मंदिर में अन्य भक्तों के बीच बांटा जाएगा।",
        "वीडियो प्रमाण उपलब्ध है।",
      ],
    },
    {
      value: "nodel",
      title: "No Delivery – Blessings for All",
      description: [
        `Full ${totalWeight} grams of Prasad will be offered to devotees at the temple.`,
        "Video proof available.",
      ],
      hdescription: [
        `पूरा ${totalWeight} ग्राम प्रसाद मंदिर में भक्तों को अर्पित किया जाएगा।`,
        "वीडियो प्रमाण उपलब्ध है।",
      ],
    },
  ];

  const handlePay = () => {
    if (user.userAddress.length > 0) {
      setPay(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setDeliveryCharge(
        selectedIndexDel === 0 ? del : selectedIndexDel === 1 ? ced : 0
      );
      setFormData({
        ...formData,
        deliveryType: radioOptions[selectedIndexDel].value,
      });
    }
  };
  // const placed = () =>
  //   orderPlaced ? "bg-white text-blue-500" : "bg-blue-500 text-white";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (orderPlaced || cartItems.length === 0) {
      toast.error("Please fill the cart.");
      return;
    }

    if (!formData.paymentMethod) {
      toast.error("Please choose a payment method.");
      return;
    }
    if (formData.contactNo > 9999999999 || formData.contactNo < 1000000000) {
      toast.error("Mobile number must be 10 digits.");
      return;
    }
    let totalAmount = formatDecimals(
      deliveryCharge + total + 0 + mannat + codCharge
    );
    setLoading(true);
    const dataToSubmit = {
      ...formData,
      paymentId: "",
      addMannat: AddMannat ? "True" : "False",
      total: totalAmount,
      delivery: radioOptions[selectedIndexDel].value,
      selectedIndex: selectedIndex,
      cartItems: JSON.stringify(
        cartItems.map((item) => ({
          id: item.id,
          price: item.price,
          quantity: item.quantity,
        }))
      ),
    };
    try {
      let response;
      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();

      if (formData.paymentMethod === "COD") {
        handleClick("User", "Payment COD", "Payment", 1);
        response = await PayCod(
          cartItems,
          dataToSubmit,
          deliveryCharge,
          totalAmount,
          user,
          setFormData,
          token,
          navigate,
          dispatch,
          abortController
        );
      } else {
        handleClick("User", "Payment Online", "Payment", 1);
        response = await initiatePayment(
          cartItems,
          dataToSubmit,
          deliveryCharge,
          totalAmount,
          user,
          setFormData,
          token,
          navigate,
          dispatch,
          abortController
        );
      }
      if (response === "success") {
        cartItems.forEach(handleRemove);
        setOrderPlaced(true);

        navigate("/dashboard", { state: { name: formData.name } });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        throw new Error(response.data.message || "Unknown error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[100vh] ">
      <div
        className="md:mt-12 mt-12 mb-10 md:mb-0 bg-white "
        style={{
          minHeight: "400px",
          // background:  "linear-gradient(140deg, rgba(245, 130, 52, 0.31), rgba(245, 130, 52, 0.31))",
        }}
      >
        <div className="relative">
          {/* {
            <div className="sm:flex absolute -left-0 -top-0 -z-10 hidden items-center justify-center">
              <img
                src={rcircle}
                alt="Circle decoration"
                className="mix-blend-color-burn"
                style={{ width: "300px", height: "auto" }}
              />
            </div>
          }
          <div className="absolute -right-0 -top-0 md:z-auto -z-10 flex items-center justify-center">
            <img
              src={circle}
              alt="Circle decoration"
              className="mix-blend-color-burn"
              style={{ width: "300px", height: "auto" }}
            />
          </div> */}

          {pay ? (
            <div className="mt-0">
              <div className="max-w-full sm:max-w-md mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_3px_40px_-7px] rounded-xl mx-4  p-4 mt-16 ">
                    <h2 className="md:text-3xl text-xl font-bold mb-4  ">
                      {language === 0
                        ? "Choose Payment Method"
                        : "भुगतान विधि चुनें "}
                    </h2>
                    {selectedIndexDel !== 2 && (
                      <div className="flex items-center md:text-xl justify-between text-lg font-semibold my-4">
                        <input
                          type="radio"
                          id="paymentMethodCOD"
                          name="paymentMethod"
                          value="COD"
                          checked={formData.paymentMethod === "COD"}
                          onChange={handleChange}
                          className="hidden placeholder:text-sm md:placeholder:text-base"
                        />
                        <label
                          htmlFor="paymentMethodCOD"
                          className="flex items-center cursor-pointer"
                        >
                          <div
                            className={`w-6 h-6 border-2 text-xs ${
                              formData.paymentMethod === "COD"
                                ? "border-orange-500"
                                : "border-black"
                            } rounded-full flex items-center justify-center mr-2`}
                          >
                            {formData.paymentMethod === "COD" && (
                              <div className="w-4 h-4 bg-orange-500 rounded-full"></div>
                            )}
                          </div>
                          Cash on Delivery
                        </label>
                        <div className="flex items-end font-normal text-gray-500 text-sm mt-2">
                          <div>COD Charge:</div>
                          <div>₹{formatDecimals(43)} </div>
                        </div>
                      </div>
                    )}

                    <div className="flex items-center md:text-xl text-lg font-semibold">
                      <input
                        type="radio"
                        id="paymentMethodOnline"
                        name="paymentMethod"
                        value="online"
                        checked={formData.paymentMethod === "online"}
                        onChange={handleChange}
                        className="hidden placeholder:text-sm md:placeholder:text-base"
                      />
                      <label
                        htmlFor="paymentMethodOnline"
                        className="flex items-center cursor-pointer"
                      >
                        <div
                          className={`w-6 h-6 border-2 ${
                            formData.paymentMethod === "online"
                              ? "border-orange-500"
                              : "border-black"
                          } rounded-full flex items-center justify-center mr-2`}
                        >
                          {formData.paymentMethod === "online" && (
                            <div className="w-4 h-4 bg-orange-500 rounded-full"></div>
                          )}
                        </div>
                        Online Payment
                      </label>
                    </div>
                  </div>
                  <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] bg-orange-500 text-white mx-4 p-4 mt-8 rounded-2xl">
                    <h2 className="md:text-3xl text-xl font-bold mb-4">
                      {language === 0 ? "Order Summary" : "ऑर्डर का सारांश"}
                    </h2>
                    <div className="flex justify-between mb-4">
                      <span>Subtotal:</span>
                      <span>₹{formatDecimals(total)} </span>
                    </div>
                    <div className="flex justify-between mb-4">
                      <span>Delivery Charge:</span>
                      <span>₹{formatDecimals(deliveryCharge)} </span>
                    </div>
                    <div className="flex justify-between mb-4">
                      <span>COD Charge:</span>
                      <span>₹{formatDecimals(codCharge)} </span>
                    </div>
                    <div className="flex justify-between mb-4">
                      <span>Service Charge:</span>
                      <span>₹{formatDecimals(0)} </span>
                    </div>
                    <div className="flex justify-between mb-4">
                      <span>Mannat Charge:</span>
                      <span>₹{formatDecimals(mannat)} </span>
                    </div>

                    <div className="flex justify-between font-bold border-white border-dashed   border-t-2 pt-4">
                      <span>Total:</span>
                      <span>
                        ₹
                        {formatDecimals(
                          deliveryCharge + mannat + total + codCharge + 0
                        )}{" "}
                      </span>
                    </div>
                    <button
                      type="submit"
                      disabled={loading}
                      className={`w-full rounded-xl font-bold hover:scale-105 mt-6 bg-white text-orange-500 p-4 transition-all duration-300 ease-in-out`}
                    >
                      {loading ? "Processing..." : "Place My Order"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="max-w-full sm:max-w-md mx-auto p-4">
              <div>
                <p className="text-3xl font-bold">Address</p>
                {user?.userAddress?.length === 0 ? (
                  <Address
                    userAddress={
                      userAddress.length > 0
                        ? userAddress
                        : setUserAddress([
                            { name: user?.name, contactNo: user?.contactNo },
                          ])
                    }
                    setUserAddress={setUserAddress}
                    index={0}
                    language={0}
                    setFormData={setFormData}
                    formData={formData}
                    isShow={false}
                    setShow={setShow}
                    token={token}
                  />
                ) : (
                  <div className="mt-3">
                    {userAddress.map((address, index) => (
                      <div
                        key={index}
                        className={` border-2 bg-white py-3 p-2 shadow-[0_5px_30px_rgb(0,0,0,0.12)] mt-3 ${
                          selectedIndex === index
                            ? "border-orange-500"
                            : "border-white"
                        }  rounded-2xl `}
                      >
                        <input
                          type="radio"
                          checked={selectedIndex === index}
                          onChange={() => toggleSelectedAddress(index)}
                          className="hidden placeholder:text-sm md:placeholder:text-base"
                          id={`${index}`}
                        />
                        <label className=" cursor-pointer" htmlFor={`${index}`}>
                          <div className="flex items-center gap-2">
                            <div
                              className={`w-6 h-6 border-2 text-xs ${
                                selectedIndex === index
                                  ? "border-orange-500"
                                  : "border-black"
                              } rounded-full flex items-center justify-center`}
                            >
                              {selectedIndex === index && (
                                <div className="w-4 h-4 bg-orange-500 rounded-full"></div>
                              )}
                            </div>
                            <div className="flex w-full justify-between">
                              <div className="text-xl font-semibold">
                                {index === 0 ? "Home" : `Friend`}
                              </div>
                              <button
                                onClick={() => onEditAddress(index)}
                                className="underline text-orange-500 underline-offset-2 cursor-pointer"
                              >
                                Edit
                              </button>
                            </div>
                          </div>

                          <div className="ml-8 py-2 text-gray-500">
                            {user?.userAddress[index] ? (
                              <>
                                {user?.userAddress[index]?.address},{" "}
                                {user?.userAddress[index]?.district},{" "}
                                {user?.userAddress[index]?.state},{" "}
                                {user?.userAddress[index]?.pincode}
                              </>
                            ) : null}
                          </div>
                        </label>
                        <Address
                          userAddress={userAddress}
                          setUserAddress={setUserAddress}
                          index={index}
                          language={language}
                          setFormData={setFormData}
                          formData={formData}
                          isShow={show[index]}
                          isSelected={selectedIndex === index}
                          setShow={setShow}
                          token={token}
                        />
                      </div>
                    ))}
                    <div
                      className={`flex justify-end items-center underline-offset-2 mt-5  text-orange-500 underline ${
                        userAddress.length >= 2 ? "hidden" : "block"
                      }`}
                    >
                      <div className={` relative top-[0.09rem] `}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#F97316"
                        >
                          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                        </svg>
                      </div>
                      <button onClick={onAddNewAddress}>Add Address</button>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <p className="text-3xl font-bold">Delivery</p>

                <div>
                  {radioOptions.map((option, index) =>
                    option.value === "ced" && totalWeight <= 500 ? null : (
                      <div
                        key={option.value}
                        className={`border-2 bg-white py-3 p-2 shadow-[0_5px_30px_rgb(0,0,0,0.12)] mt-3 ${
                          selectedIndexDel === index
                            ? "border-orange-500"
                            : "border-white"
                        } rounded-2xl`}
                      >
                        <input
                          type="radio"
                          checked={selectedOptionDel === option.value}
                          onChange={() =>
                            handleRadioChange(option.value, index)
                          } // Handle the radio change
                          className="hidden"
                          id={`${option.value}`}
                          name="radioOption" // Ensure unique name for all radio buttons
                        />
                        <label
                          className="cursor-pointer"
                          htmlFor={`${option.value}`}
                        >
                          <div className="flex items-center gap-2">
                            <div
                              className={`w-6 h-6 border-2 text-xs ${
                                selectedIndexDel === index
                                  ? "border-orange-500"
                                  : "border-black"
                              } rounded-full flex items-center justify-center `}
                            >
                              {selectedIndexDel === index && (
                                <div className="w-4 h-4 bg-orange-500 rounded-full"></div>
                              )}
                            </div>
                            <div className="flex w-full justify-between">
                              <div className="text-xl font-semibold">
                                {option.title}{" "}
                              </div>
                              <div className="text-xl font-bold text-green-500">
                                ₹
                                {option.value === "ced"
                                  ? ced
                                  : option.value === "std"
                                  ? del
                                  : 0}
                              </div>
                            </div>
                          </div>
                          {(language === 0
                            ? option.description
                            : option.hdescription) &&
                          Array.isArray(
                            language === 0
                              ? option.description
                              : option.hdescription
                          ) ? (
                            <ul className="ml-8 py-2 text-gray-500">
                              {(language === 0
                                ? option.description
                                : option.hdescription
                              ).map((item, idx) => (
                                <li className="text-sm" key={idx}>
                                  <i className="fas fa-circle mr-2 text-[0.5rem] text-orange-500"></i>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="ml-8 py-2 text-gray-500">
                              {language === 0
                                ? option.description
                                : option.hdescription}
                            </p>
                          )}
                        </label>
                      </div>
                    )
                  )}
                  <div className="flex items-center justify-center bottom-0 w-full fixed">
                    {/* <button
                      type="submit"
                      className={`p-3 px-12 text-xl  mt-6 bg-orange-500 text-white rounded-xl transition-all duration-300 ease-in-out`}
                      onClick={handlePay}
                    >
                      {"Continue"}
                    </button> */}
                    <button
                      className={`fixed z-10 md:text-xl text-lg text-center font-bold md:bottom-14 bottom-14 w-[80.69%] sm:w-auto md:left-auto md:right-32  left-9 border-2 border-orange-500 bg-orange-500 text-white md:p-4 p-2 rounded-xl transition-all duration-300 shadow-[6px_6px_24px_rgba(0,0,0,0.9)] ease-in-out transform hover:bg-white hover:text-orange-500 hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:scale-110 cursor-pointer   md:px-16 `}
                      onClick={handlePay}
                    >
                      {"Continue"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
