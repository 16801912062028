import React from "react";

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-md w-96">
        <h2 className="text-lg font-semibold text-center">{message}</h2>
        <div className="flex justify-between mt-4">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded-md"
            onClick={onConfirm}
          >
            Yes, delete my account
          </button>
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded-md"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
