import React, { useState } from "react";
import { useSelector } from "react-redux";
import flogo from "./Images/logo.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Footer = ({ handleClick }) => {
  const language = useSelector((state) => state.language.language);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    feedback: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClick("User", "Submitted Feedback Form", "Feedback Form", 1);
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_APP_FEEDBACK_SENDER_IVAR_HIN_API,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result === "success") {
        toast.success("Feedback submitted successfully");
        setFormData({ name: "", contact: "", email: "", feedback: "" });
      } else {
        throw new Error(response.data.message || "Unknown error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  const handleClickTNC = (e) => {
    e.preventDefault();
    navigate("/policy");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-white text-black pt-6 md:mt-10 mt-10 md:mx-3">
      <div className="container sm:gap-14 lg:mx-auto md:mr-0 md:pr-0 px-4 flex flex-col md:flex-row justify-between gap-10 items-start border-b-2 pb-10 border-orange-500">
        <div className="mb-6 md:mb-0 md:w-5/12 w-full">
          <h3 className="sm:text-3xl text-xl text-center sm:text-left font-bold text-orange-500">
            About Us
          </h3>
          {language === 0 ? (
            <p className="md:mt-7 mt-4 md:pr-10 sm:text-xl text-base text-justify">
              Jai Shri Shyam! Having been a resident of Khatu since childhood, I
              have always felt a deep connection to Khatu Naresh. Over time, I
              pursued my education in Khatu and Kota and later enrolled at IIT
              Dhanbad. It was during this journey that the idea of starting this
              service came to me. Along with my friends, I formed a team to
              bring this vision to life, and we are fully committed to providing
              an exceptional experience for all devotees. With your trust and
              support, we strive to continuously improve our services. We aim to
              ensure that every offering to Khatu Shyam Ji is made with utmost
              devotion and transparency. For us, trust and transparency are
              always of the highest priority. Together, we endeavor to make this
              sacred connection even more special and meaningful for you.
            </p>
          ) : (
            <p className="mt-7 md:pr-10 pr-0 text-2xl text-justify">
              जय श्री श्याम! बचपन से ही खाटू श्याम का निवासी होने की वजह से मुझे
              खाटू नरेश से लगाव रहा है। समय के साथ मैने खाटू और कोटा से शिक्षा
              प्राप्त की और IIT धनबाद में दाखिला लिया और फिर वही मेरे को इस
              सुविधा को शुरू करने का खयाल आया। मैंने अपने मित्रों के साथ एक टीम
              बना के इस सुविधा को शुरू किया, और हम भक्तों को बेहतरीन अनुभव
              प्रदान करने के लिए पूरी तरह से प्रतिबद्ध हैं। आपके विश्वास और
              समर्थन के साथ, हम अपनी सेवाओं को लगातार बेहतर बनाने की कोशिश कर
              रहे हैं। हम यह सुनिश्चित करना चाहते हैं कि खाटू श्याम जी को हर
              अर्पण को पूरी भक्ति और पारदर्शिता के साथ किया जाए। हमारे लिए
              विश्वास और पारदर्शिता हमेशा सर्वोच्च प्राथमिकता है। हम मिलकर इस
              पवित्र संबंध को आपके लिए और भी विशेष और अर्थपूर्ण बनाने का प्रयास
              करेंगे।
            </p>
          )}
        </div>

        <div className="md:w-6/12 w-full">
          <h3 className="sm:text-3xl text-xl text-center sm:text-left font-bold text-orange-500">
            Feedback
          </h3>
          <form
            onSubmit={handleSubmit}
            className="bg-gradient-to-r from-amber-400 to-amber-500 border-t-2  p-8 mt-4 rounded-xl"
          >
            <div className="mb-4">
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                placeholder="Name"
                onChange={handleChange}
                required
                className="w-full p-2 rounded border border-gray-600 placeholder:text-sm md:placeholder:text-lg"
              />
            </div>
            <div className="mb-4">
              <input
                type="number"
                min="0"
                name="contact"
                placeholder="Contact"
                id="contact"
                value={formData.contact}
                onChange={handleChange}
                required
                className="w-full p-2 rounded border border-gray-600 placeholder:text-sm md:placeholder:text-lg"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 rounded border border-gray-600 placeholder:text-sm md:placeholder:text-lg"
              />
            </div>
            <div className="mb-4">
              <textarea
                name="feedback"
                id="feedback"
                placeholder="Feedback"
                value={formData.feedback}
                onChange={handleChange}
                required
                className="w-full p-2 min-h-20 rounded border border-gray-600 placeholder:text-sm md:placeholder:text-lg"
              />
            </div>
            <div className="w-full">
              <button
                type="submit"
                className="p-3 md:text-xl text-sm w-full text-white bg-orange-500 rounded-2xl font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500  hover:border-orange-500 hover:shadow-lg hover:rounded-xl hover:border-2"
              >
                {loading
                  ? "Submitting..."
                  : language === 0
                  ? "Submit"
                  : "सबमिट करें"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between md:pt-4 pt-2 px-4 m-4 text-black">
        <div className="flex md:justify-between flex-col-reverse md:flex-row md:w-7/12 w-full">
          <div className="flex flex-col items-center mb-4 gap-2 md:mt-0 mt-4">
            <div className="flex items-center justify-center md:justify-start">
              <img src={flogo} alt="Logo" className="h-10 w-auto mr-2" />
              <p className="text-xl ml-2 font-semibold text-orange-500">
                ParamBhog
              </p>
            </div>
            <h2 className="text-sm">
              © 2024 ParamBhog, Inc. All rights reserved.
            </h2>
            <button
              onClick={handleClickTNC}
              className="text-sm underline cursor-pointer text-blue-600 hover:text-blue-800 hover:shadow-md transition duration-200"
            >
              Terms and Conditions
            </button>
          </div>

          <div className="flex md:gap-2 gap-2 justify-center font-bold items-center">
            <p className="md:text-2xl text-sm">Made with </p>
            <p className="md:text-3xl text-xl font-bold text-orange-500">
              {" "}
              श्रद्धा{" "}
            </p>
            <p className="md:text-2xl text-sm">by ParamBhog Team</p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center space-x-4 gap-2 justify-center">
            <h1 className="md:text-2xl text-lg font-semibold">Follow Us:</h1>
            <a
              href="https://wa.me/message/65UIK7V2O2C3I1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-green-500"
            >
              <i className="fab fa-whatsapp md:text-5xl text-3xl"></i>
            </a>
            <a
              href="https://www.instagram.com/parambhog_official"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-pink-500"
            >
              <i className="fab fa-instagram md:text-5xl text-3xl"></i>
            </a>
            <a
              href="https://www.youtube.com/@ParamBhog"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-youtube"
            >
              <i className="fab fa-youtube md:text-5xl text-3xl"></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61567041992628"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-blue-600"
            >
              <i className="fab fa-facebook md:text-5xl text-3xl"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
