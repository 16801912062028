import React from "react";
import html2canvas from "html2canvas";
import shareLetter from "./Images/share_letter.png";

const DownloadDivAsPNG = ({ name, setName }) => {
  const handleDownload = () => {
    const div = document.getElementById("downloadDiv");

    html2canvas(div, { useCORS: true })
      .then((canvas) => {
        // Create the download link for PNG
        const link = document.createElement("a");
        link.download = "ParamBhog.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
        setName(null);
      })
      .catch(() => {});
  };

  // const shareOnWhatsApp = (base64Image) => {
  //   const message = `Check out this image I made!%0A${base64Image}`;
  //   const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
  //   window.open(whatsappUrl, "_blank");
  // };

  // const shareOnFacebook = (base64Image) => {
  //   const message = `Check out this image I made!%0A${base64Image}`;
  //   const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //     message
  //   )}`;
  //   window.open(facebookUrl, "_blank");
  // };

  return (
    <div className="space-y-4 bg-transparent">
      {/* 
      <div className="flex space-x-4">
        <button
          onClick={() => shareOnWhatsApp("")}
          className="bg-green-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-green-600 transition"
        >
          Share on WhatsApp
        </button>
        <button
          onClick={() => shareOnFacebook("")}
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition"
        >
          Share on Facebook
        </button>
      </div> */}

      <div
        id="downloadDiv"
        className="w-auto h-full text-center relative flex items-center justify-center"
      >
        <p
          className="absolute top-[19vh] md:top-44 w-full text-white text-xl font-semibold"
          style={{ fontFamily: "Jakarta Sans" }}
        >
          {name} ji
        </p>

        <img
          src={shareLetter}
          alt="Share Letter"
          className="w-full h-96 rounded-3xl"
          crossOrigin="anonymous"
        />
      </div>
      <button
        onClick={handleDownload}
        className="w-full bg-blue-500 text-white py-2 rounded-md shadow-md hover:bg-blue-600 transition"
      >
        Download and Share
      </button>
    </div>
  );
};

export default DownloadDivAsPNG;
