import axios from "axios";
// import { SaveExcel } from "./SaveExcel";
import toast from "react-hot-toast";
import { setUserPay } from "../Redux/userActions";

export async function PayCod(
  cartItems,
  dataToSubmit,
  deliveryCharge,
  totalAmount,
  user,
  setFormData,
  token,
  navigate,
  dispatch,
  abortController
) {
  const total = totalAmount;
  const toastId = toast.loading("Loading...");

  try {
    dispatch(setUserPay(dataToSubmit));
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/paymentcod`,
      {
        cartItems,
        token,
        user,
        total,
        dataToSubmit,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
        signal: abortController.signal,
      }
    );

    if (response.data.success) {
      toast.success(
        "Thank you for your order 🙏. We will reach out to you soon."
      );
      return "success";
    } else {
      // console.log(response.data);
      throw new Error(response.data.message || "Payment processing failed");
    }
  } catch (error) {
    toast.error(error.message || "Unknown error");
  } finally {
    toast.dismiss(toastId);
  }
}
