import React, { useState, useEffect, useRef } from "react";
import "../App.css";

const HomeImagesSlider = ({ homeImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === homeImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3500);
    return () => clearInterval(intervalId);
  }, [homeImages.length]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      setCurrentIndex((prevIndex) =>
        prevIndex === homeImages.length - 1 ? 0 : prevIndex + 1
      );
    } else if (touchEndX.current - touchStartX.current > 50) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? homeImages.length - 1 : prevIndex - 1
      );
    }
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div
      className="relative w-full h-[55vh] md:h-[70vh] overflow-hidden"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {homeImages.map((image, index) => (
          <div key={index} className="w-full flex-shrink-0 relative">
            <img
              // Responsive images with srcSet
              src={image.link}
              alt={image.text}
              className="w-full h-[55vh] md:h-[70vh] object-cover shadow-md"
              loading={index === 0 ? "eager" : "lazy"} // Preload the first image, lazy load others
              srcSet={`${image.link}?w=600&h=400 600w, ${image.link}?w=1200&h=800 1200w, ${image.link}?w=2000&h=1300 2000w`}
              sizes="(max-width: 600px) 600px, (max-width: 1024px) 1200px, 2000px" // Sets which image size to use at different screen widths
            />
          </div>
        ))}
      </div>

      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full bg-white bg-opacity-40 backdrop-blur-lg p-2 text-center crushed-border">
        <div className="text-3xl gotu-regular mb-2">🌹 जय श्री श्याम 🌹</div>
        <div className="mb-4">{homeImages[currentIndex]?.text}</div>
      </div>

      <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {homeImages.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex
                ? "bg-orange-500"
                : "bg-white bg-opacity-50"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default HomeImagesSlider;
