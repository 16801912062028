import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../App.css";
import mannat from "./Images/mannat.jpeg";
import mor from "./Images/mor.png";
import data from "../data.json";
import { FiX } from "react-icons/fi";
import handleBuy from "./useCart";
const Cart = ({ handleClick }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const language = useSelector((state) => state.language.language);
  const cartItems = useSelector((state) => state.cart.items || []);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const addTotal = () => {
    let tot = 0;
    let tw = 0;
    cartItems.forEach((item) => {
      tot += item.price * item.quantity;
      tw += item.weight * item.quantity;
    });
    setTotal(tot);
    setTotalWeight(tw);
  };

  useEffect(() => {
    addTotal();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [cartItems]);
  const calculateWeight = (weight, quantity) => {
    let wt = weight;
    if (quantity !== 0) wt *= quantity;
    return wt < 1000 ? `${wt} gm` : `${(wt / 1000).toFixed(2)} kg`;
  };
  const [orderPlaced, setOrderPlaced] = useState(false);
  const handleIncrease = (item) => {
    handleBuy(
      dispatch,
      item,
      item.price,
      item.weight,
      cartItems,
      setOrderPlaced,
      item.quantity + 1
    );
  };

  const handleDecrease = (item) => {
    if (item.quantity > 1) {
      handleBuy(
        dispatch,
        item,
        item.price,
        item.weight,
        cartItems,
        setOrderPlaced,
        item.quantity - 1
      );
    } else {
      handleRemove(item);
    }
  };

  const handleRemove = (item) => {
    item.quantity = 0;
    handleBuy(
      dispatch,
      item,
      item.price,
      item.weight,
      cartItems,
      setOrderPlaced,
      0
    );
  };
  if (cartItems.length === 0) {
    setTimeout(() => {
      navigate("/");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  }

  // const handleCheckout = () => {
  //   handleClick("User", "Going to Cart", "Cart", 1);
  //   if (user) {
  //     navigate("/payment");
  //   } else {
  //     navigate("/login");
  //   }
  // };
  // const popText = [
  //   "Due to large number of prasad requests on Ekadashi (Gyaras), your offering is in the queue. Please share your contact number, and we will reach out as soon as it's your turn.  ",
  //   "एकादशी (ग्यारस)  पर अधिक प्रसाद अनुरोधों के कारण, आपका प्रसाद कतार में है। कृपया अपना संपर्क नंबर साझा करें, जैसे ही आपकी बारी आएगी, हम आपसे संपर्क करेंगे। ",
  // ];
  // const popText2 = [
  //   "Sorry for the inconvenience.",
  //   " असुविधा के लिए हमें खेद है।",
  // ];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // const handlePopupOpen = () => {
  //   handleClick("User", "Checking Popup", "Cart", 1);
  //   setIsPopupOpen(true);
  // };
  const handleCheckoutClick = () => {
    handleClick("User", "Going to Cart", "Cart", 1);
    if (user) {
      // navigate("/payment");
      setIsPopupOpen(true);
    } else {
      navigate("/login");
    }
  };

  const handleAddClick = () => {
    setIsPopupOpen(false);
    proceedToPayment(true);
  };

  const handleContinueClick = () => {
    setIsPopupOpen(false);
    proceedToPayment(false);
  };

  const proceedToPayment = (addMannat) => {
    navigate("/payment", {
      state: { addMannat, totalWeight, totalAmount: total },
    });
  };
  // const handlePopupClose = () => setIsPopupOpen(false);

  // const handleInputChange = (e) => setMobileNumber(e.target.value);

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   if (mobileNumber.length !== 10) {
  //     toast.error("Contact No should be 10 digit");
  //     return;
  //   }
  //   const data = {
  //     contactNo: mobileNumber,
  //   };

  //   const loadingToast = toast.loading("Submitting ...");

  //   try {
  //     handleClick("User", "Submitting Contact No", "Cart", 1);
  //     const response = await axios.post(
  //       process.env.REACT_APP_CONTACT_NO,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     if (response.data.result === "success") {
  //       setIsFormSubmitted(true);
  //       toast.success("Submitted successfully!", {
  //         id: loadingToast,
  //       });
  //       setIsPopupOpen(false);
  //       setTimeout(() => {
  //         navigate("/");
  //         window.scrollTo({
  //           top: 0,
  //           behavior: "smooth",
  //         });
  //       }, 1000);
  //     } else {
  //       toast.error("Submission failed. Please try again.", {
  //         id: loadingToast,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong. Please try again later.", {
  //       id: loadingToast, // Reference to the loading toast
  //     });
  //   }
  // };
  const weightWarning = [
    "Total weight should be less than 2 kg!",
    "कुल वजन 2 किलोग्राम से कम होना चाहिए!",
  ];
  const weightWarning2 = [`Current Weight `, `वर्तमान वजन  `];
  const formatToTwoDecimals = (num) => parseFloat(num).toFixed(2);

  return (
    <div className="">
      {/* <div>
        <div className="flex items-center justify-center bg-gray-100">
          {isPopupOpen && (
            <div
              className="fixed inset-0 bg-black  bg-opacity-50 flex justify-center items-center z-50"
              onClick={handlePopupClose}
            >
              <div
                className="bg-white p-8 rounded-lg md:w-full  w-[18rem] font-semibold leading-6 max-w-md relative"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={handlePopupClose}
                  className="absolute top-1 right-4 text-gray-600 text-2xl"
                >
                  &times;
                </button>

                <h1 className="text-center text-3xl">🙏</h1>
                <p className="text-gray-700 text-justify mb-4">{popText[1]}</p>
                <p className="text-gray-700 text-center mb-4">{popText2[1]}</p>

                {!isFormSubmitted ? (
                  <form onSubmit={handleFormSubmit}>
                    <div className="space-y-4">
                      <div>
                      
                        <input
                          type="number"
                          min={0}
                          id="mobile"
                          name="mobile"
                          placeholder="Whatsapp No."
                          value={mobileNumber}
                          onChange={handleInputChange}
                          className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                          required
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="bg-orange-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-orange-600"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="text-center text-gray-700">
                    <p>Thank you! We have received your mobile number.</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* <div className="h-20"></div> */}
      <div className="min-h-[100vh] pt-14 md:p-16 bg-gray-50 ml-0">
        <p
          className="md:text-7xl text-5xl font-extrabold md:ml-0 ml-40 mt-3"
          style={{
            background: "radial-gradient(circle, #FDC830, #F37335)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "#FDC830",
          }}
        >
          Cart
        </p>

        <div className="md:mt-6 mt-0 ">
          {cartItems.length === 0 ? (
            <p className="ml-4 mt-3 md:text-xl text-lg  font-semibold">
              Cart is empty.
            </p>
          ) : (
            <ol className="mt-6 mx-5">
              {cartItems.map((item, index) => {
                const product = data.find((obj) => obj.id === item.id);
                if (!product) {
                  return (
                    <li key={index} className="text-red-500">
                      Product not found
                    </li>
                  );
                }
                return (
                  <li
                    key={index}
                    className="flex p-3 shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] rounded-[1.3rem] text-xl justify-between mb-5"
                  >
                    <div className="w-1/3">
                      <img
                        src={product.image}
                        alt={product.title}
                        className="w-24 h-24 object-cover rounded-xl "
                      />
                    </div>
                    <div className="w-1/2 flex flex-col ml-8 justify-between">
                      <div className="mt-1">
                        <p className="font-semibold ">
                          {language === 0 ? product.title : product.hTitle}
                        </p>
                        <div className="text-gray-600 -mt-1 text-base">
                          {calculateWeight(item.weight, item.quantity)}
                        </div>
                      </div>
                      <div>
                        <p className=" font-bold -mt-10 text-orange-600">
                          {" "}
                          ₹{item.price}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/5 flex flex-col-reverse gap-0 justify-between items-end md:col-span-1 text-right">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className={`cursor-pointer text-2xl text-orange-500 `}
                        onClick={() => handleDecrease(item)}
                      />
                      <span className="font-semibold mx-2 text-xl">
                        {item.quantity}
                      </span>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className={`cursor-pointer text-2xl ${
                          item.id > 6 ? "text-gray-500" : "text-orange-500"
                        }`}
                        onClick={() => handleIncrease(item)}
                      />
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>

        <div className="">
          {cartItems.length > 0 && totalWeight <= 2000 ? (
            <div className="flex flex-row-reverse md:justify-start justify-right mr-6 items-center mt-6">
              {/* <button
                onClick={handlePopupOpen}
                className={`p-4 text-white md:text-lg text-xs rounded-full font-bold hover:cursor-pointer transition ${language ===1 ? 'px-12': 'px-6'} duration-300 ease-in-out transform hover:scale-105 
         bg-orange-500 hover:bg-white hover:text-orange-500 border hover:border-orange-500 hover:shadow-lg"`}
              >
                {language === 0 ? "Checkout" : "खरीदे"}
              </button> */}
              <button
                onClick={handleCheckoutClick} // Use onClick to handle navigation
                className={`p-4 text-white md:text-lg text-xs rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 shadow-[rgba(0,_0,_0,_0.2)_0px_27px_40px_-7px]
              ${
                user
                  ? "bg-orange-500 hover:bg-white hover:text-orange-500 border hover:border-orange-500 hover:shadow-lg"
                  : "bg-red-500 hover:bg-white hover:text-red-500 border hover:border-red-500 hover:shadow-lg"
              }`}
              >
                {user
                  ? language === 0
                    ? "Checkout"
                    : "खरीदे"
                  : language === 0
                  ? "Login to Proceed"
                  : "जारी रखने के लिए लॉगिन करें"}
              </button>
            </div>
          ) : null}
        </div>
        {isPopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
            <div className="bg-white py-4  rounded-3xl text-center">
              {/* <button
                onClick={() => setIsPopupOpen(false)}
                style={{ fontSize: "30px" }}
                className="absolute top-[15.5rem] md:right-[40vw] right-10 text-black p-2 rounded-full"
              >
                <FiX />
              </button> */}
              <h2 className="text-3xl px-14 pb-2 border-b-2 border-black">
                Ekadashi Offer
              </h2>

              <h3 className="text-3xl font-bold"> Mannat</h3>
              <div className="flex gap-6 px-14 mt-4">
                <img
                  src={mannat}
                  alt="Mannat"
                  className="rounded-lg w-28 h-24 object-cover"
                />
                <div className="text-left ml-5">
                  <div className="flex items-center">
                    <h1 className="text-xl font-semibold">इत्र</h1>
                    <button
                      className="ml-2 bg-black text-white rounded-full w-4 h-4 flex items-center justify-center hover:bg-gray-700 font-bold"
                      onMouseEnter={() => setIsInfoPopupOpen(true)}
                      onMouseLeave={() => setIsInfoPopupOpen(false)}
                      style={{ fontSize: "10px" }}
                    >
                      i
                    </button>
                    {isInfoPopupOpen && (
                      <div className="absolute left-[57vw]  text-xs max-w-[270px] bg-white border border-gray-300 p-2 rounded shadow-lg font-bold">
                        <p>
                          भक्तों में मान्यता है कि, मनोकामना पूरी होने पर इत्र
                          को अगली बार दर्शन के लिए आने पर,बाहर जमा करा देते हैं।
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="mt-5 flex items-baseline gap-4">
                    <p>
                      <span className=" text-lg">₹13</span>
                    </p>
                    <span className="line-through text-sm text-gray-500">
                      ₹26
                    </span>
                    {/* <p>
                      FREE{" "}
                      <span className="line-through text-xs text-gray-500">
                        ₹13
                      </span> 
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="flex gap-6 px-14 mt-4">
                <img
                  src={mor}
                  alt="Mor Pankh"
                  className="rounded-lg w-28 h-24 object-cover"
                />
                <div className="text-left ml-5">
                  <div className="flex items-center">
                    <h1 className="text-xl font-semibold">मोर पंख </h1>
                    <button
                      className="ml-2 bg-black text-white rounded-full w-4 h-4 flex items-center justify-center hover:bg-gray-700 font-bold"
                      onMouseEnter={() => setIsInfoPopupOpen(true)}
                      onMouseLeave={() => setIsInfoPopupOpen(false)}
                      style={{ fontSize: "10px" }}
                    >
                      i
                    </button>
                    {isInfoPopupOpen && (
                      <div className="absolute left-[57vw] text-xs max-w-[270px] bg-white border border-gray-300 p-2 rounded shadow-lg font-bold">
                        <p>Length of pankh is 13 - 16 cm .</p>
                      </div>
                    )}
                  </div>
                  <div className="mt-5 flex items-baseline gap-4">
                    <p>
                      <span className=" text-lg">₹11</span>
                    </p>
                    <span className="line-through text-sm text-gray-500">
                      ₹24
                    </span>
                    {/* <p>
                      FREE{" "}
                      <span className="line-through text-xs text-gray-500">
                        ₹13
                      </span> 
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  onClick={handleAddClick}
                  className="bg-green-600 p-3 px-12 rounded-3xl text-white"
                >
                  Add With Prasad
                </button>
                <div>
                  <button
                    onClick={handleContinueClick}
                    className="text-blue-500 mt-2 underline"
                  >
                    Continue without Mannat
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="h-6"></div>
        {totalWeight > 2000 && (
          <div className=" mx-auto text-youtube">
            <div className="font-bold  md:text-xl text-wrap text-center ">
              {weightWarning[language]}
            </div>
            <div className="font-bold  md:text-xl text-wrap text-center">
              {weightWarning2[language]}:{" "}
              {formatToTwoDecimals(totalWeight / 1000)} Kg{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
