import { ADD_TO_CART, ADD_TO_CART_TSHIRT } from "./cartActions";
import { showToast } from "../service/Toast";

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
};

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? decodeURIComponent(match[2]) : null;
};

let initialState = {
  items: [],
};

const cookieValue = getCookie("paramCart");

if (cookieValue) {
  try {
    const parsedCookieValue = JSON.parse(cookieValue);
    initialState.items = parsedCookieValue.items || [];
  } catch (error) {
    // console.error("Failed to parse cart items from cookie:", error);
  }
}

const setCartCookie = (cartItems) => {
  const cookieValue = JSON.stringify({
    items: cartItems,
  });
  setCookie("paramCart", cookieValue, 7);
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {

      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.id === action.payload.id && item.price === action.payload.price
      );

      let updatedItems;

      if (existingItemIndex !== -1) {
   
        const updatedItem = {
          ...state.items[existingItemIndex],
          quantity: action.payload.quantity,
        };

        updatedItems = [
          ...state.items.slice(0, existingItemIndex),
          updatedItem,
          ...state.items.slice(existingItemIndex + 1),
        ];
      } else {
      
        updatedItems = [
          ...state.items,
          {
            id: action.payload.id,
            price: action.payload.price,
            quantity: 1,
            weight: action.payload.weight,
          },
        ];
      }

      updatedItems = updatedItems.filter((item) => item.quantity > 0);
      setCartCookie(updatedItems);

      return { items: updatedItems };
    }

    case ADD_TO_CART_TSHIRT: {
      if (state.items.length >= 6) {
        showToast("Error: You can only have up to 6 items in your cart.");
        return state; 
      }

      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.tag === action.payload.tag && item.price === action.payload.price
      );

      let updatedItems;

      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...state.items[existingItemIndex],
          quantity: Math.min(2, state.items[existingItemIndex].quantity + 1),
        };

        updatedItems = [
          ...state.items.slice(0, existingItemIndex),
          updatedItem,
          ...state.items.slice(existingItemIndex + 1),
        ];
      } else {
        updatedItems = [
          ...state.items,
          { tag: action.payload.tag, price: 499, quantity: 1 },
        ];
      }

      updatedItems = updatedItems.filter((item) => item.quantity > 0); // Remove zero-quantity items

      const newTotalWeight = updatedItems.reduce((total, item) => {
        return total + (item.weight || 0) * item.quantity;
      }, 0);

      setCartCookie(updatedItems);

      return { items: updatedItems, totalWeight: newTotalWeight };
    }

    default:
      return state;
  }
};

export default cartReducer;
