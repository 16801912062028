export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const setData = (data) => ({
  type: SET_DATA,
  payload: { data },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});

