import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import data from "../data.json";
import { Helmet } from "react-helmet";
import shyam from "./Images/shyamji.png";
import HomeImagesSlider from "./homeImagesSlider";
import ReviewSlider from "./ReviewSlider";
import HeroSection from "./HeroSection";
import MainHome from "./MainCard";

const homeImages = [
  {
    link: "https://res.cloudinary.com/dtit4w15r/image/upload/v1734690929/Images/byrkh4lxoqqzjoyauinq.png?fm=webp",
    text: "षटतिला एकादशी के पावन अवसर पे खाटू नरेश का आशीर्वाद  पाए।",
  },
  {
    link: shyam,
    text: "प्रसाद अपने घर मंगाने के लिए आज ही आर्डर करे।",
  },
  {
    link: "https://res.cloudinary.com/dtit4w15r/image/upload/v1734690931/Images/djv8pmyrhl57c9gfv3xj.png?fm=webp",
    text: "वीडियो प्रूफ के साथ प्रसाद आपके घर डिलीवर किया जाएगा।",
  },
];

const Home = ({ handleClick }) => {
  const cartItems = useSelector((state) => state.cart.items || []);
  const itemsArray = Array.isArray(data) ? data : [];

  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    if (cartItems.length > 0) {
      setIsVisible(true);
      setAnimationClass("animate-fadeIn");
    } else {
      setAnimationClass("animate-fadeOut");
      setTimeout(() => setIsVisible(false), 500);
    }
  }, [cartItems]);

  const language = useSelector((state) => state.language.language);

  const mainRef = useRef(null);

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const handleBuyButton = (e) => {
    e.preventDefault();

    if (user) {
      navigate("/cart");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="home md:mt-0 mt-11">
      <Helmet>
        <title>ParamBhog</title>
        <meta
          name="description"
          content="With Parambhog, offer Prasad at Khatu Shyam Ji Temple from anywhere. We handle the offering and deliver the Prasad to your home, along with a video (where allowed)."
        />
        <meta
          name="keywords"
          content="ParamBhog, Param Bhog, Prasadam, Prasad, Khatu Shyam Ji,Khatu Shyam, Khatu, Mandir, Prasaad"
        />
      </Helmet>
      {isVisible && (
        <button
          className={`fixed z-10 md:text-xl text-lg text-center font-bold md:bottom-14 bottom-14 w-[80.69%] sm:w-auto md:left-auto md:right-32 left-9 border-2 border-green-500 bg-green-500 text-white md:p-4 p-2 rounded-xl 
        transition-all duration-300 shadow-[6px_6px_24px_rgba(0,0,0,0.9)] ease-in-out 
        transform hover:bg-white hover:text-green-500 hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:scale-110 cursor-pointer ${animationClass} md:px-16`}
          onClick={handleBuyButton}
        >
          {language === 0 ? "Buy " : "खरीदे "}
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      )}

      <div className="md:hidden">
        <HomeImagesSlider
          homeImages={homeImages}
          className="shadow-lg rounded-lg overflow-hidden"
        />
      </div>
      <div className="md:hidden">
        <ReviewSlider />
      </div>
      <HeroSection mainRef={mainRef} />

      <div ref={mainRef} className="h-4 absolute md:hidden"></div>
      <div ref={mainRef} className="h-12 hidden md:block"></div>
      <div ref={mainRef} className="h-4 hidden md:block"></div>
      <div className="">
        <div className="mt-0 px-0">
          <div className="relative">
            <div className="absolute -right-4 -top-4 flex items-center justify-center">
            </div>
          </div>

          <MainHome itemsArray={itemsArray} cartItems={cartItems} />
        </div>
      </div>
    </div>
  );
};

export default Home;
