import { addToCart } from "../Redux/cartActions";
import { showToast } from "../service/Toast";

const handleBuy = (
  dispatch,
  ite,
  selectedPrice,
  wt,
  cartItems,
  setOrderPlaced,
  quantity
) => {
  if (!selectedPrice) {
    selectedPrice = ite.price;
  }
  const item = cartItems.some(
    (obj) => obj.id === ite.id && ite.price === selectedPrice
  );
  if (item) {
    if ((ite.id > 6 && quantity < 2) || ite.id <= 6) {
      dispatch(
        addToCart({ ...ite, quantity, price: selectedPrice, weight: wt })
      );
      setOrderPlaced(true);
      return;
    } else {
      showToast("Error: You can only have 1 combo item in your cart.");
      return;
    }
  }

  if (cartItems.some((cartItem) => cartItem.id > 6)) {
    showToast("Error: You can only have 1 combo item in your cart.");
    return;
  } else if (cartItems.length === 1 && ite.id > 6) {
    showToast("Error: You cann't have combo and prasad in your cart.");
    return;
  } else if (cartItems.length >= 2) {
    showToast("Error: You cann't have more than 2 in your cart.");
    return;
  } else {
    dispatch(addToCart({ ...ite, quantity, price: selectedPrice, weight: wt }));
    setOrderPlaced(true);
    return;
  }
};

export default handleBuy;
