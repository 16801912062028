// src/redux/rootReducer.js
import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import languageReducer from "./languageReducer";
import userReducer from "./userReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  cart: cartReducer,
  language: languageReducer,
  user: userReducer,
  data: dataReducer,
});

export default rootReducer;
