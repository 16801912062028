import React from "react";
import { FiUser } from "react-icons/fi";

const reviews = [
  {
    id: 1,
    name: "Siddharth Agrawal",
    review:
      "🙏🌹जय श्री श्याम 🌹🙏 बाबा का प्रसाद हमारे तक पहुँचाने के लिये आपका कोटि कोटि धन्यवाद।🙏🙏",
    gender: "male",
    state: "Gujarat",
  },
  {
    id: 2,
    name: "Duleswar Sidar",
    review:
      "Receiving the sacred Parambhog Prasad from Khatu Shyam Baba is truly a divine blessing. Jai shree shyam",
    gender: "male",
    state: "Odisha",
  },
  {
    id: 3,
    name: "Tina Mithaiwala",
    review:
      "Thank You So Much for helping me out with distributing the bhog. Jai Shree Shyam Ji ",
    gender: "female",
    state: "LA, America",
  },
  {
    id: 4,
    name: "Raju Kumar",
    review:
      "दो दिन में बाबा का प्रसाद हम तक पहुंचाने के लिए आपका धन्यवाद,🌹 जय श्री श्याम 🌹",
    gender: "male",
    state: "Jharkhand",
  },
  {
    id: 5,
    name: "Srishti",
    review:
      "जय श्री श्याम जी, आपकी सेवा से सभी श्रद्धालुओं की मनोकामनाएं पूरी हो रही है।",
    gender: "female",
    state: "Himachal Pradesh",
  },
  {
    id: 6,
    name: "Vivek Rajbhar",
    review:
      "जय श्री श्याम ❤️, बहुत सुंदर काम कर रहे हो आप, मैं बहुत आभारी हूं आपका 🙏🏼",
    gender: "male",
    state: "Uttar Pradesh",
  },
  {
    id: 7,
    name: "Panchal Tushar",
    review:
      "बहुत बहुत धन्यवाद, आपकी इस सुविधा से घर बैठे भी बाबा को प्रसाद अर्पण करने का सौभाग्य मिला, दिल से बहुत बहुत धन्यवाद",
    gender: "male",
    state: "Gujarat",
  },
  {
    id: 8,
    name: "Abhishek Pandit",
    review:
      "आपकी सेवा बहुत सराहनीय है, बाबा आपको बहुत आगे लेके जाए और हम सब पे अपनी कृपा बनाए रुके, जय श्री श्याम 🌹",
    gender: "male",
    state: "Madhya Pradesh",
  },
];

const ReviewSlider = () => {
  const repeatedReviews = [...reviews, ...reviews]; 

  return (
    <div className="relative w-full overflow-hidden bg-gradient-to-r from-amber-400 to-amber-500 border-t-2 border-white">
      <div className="marquee">
        <div className="marquee-content flex gap-7">
          {repeatedReviews.map((review, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-[250px] sm:w-[300px] md:w-[350px] lg:w-[400px] flex flex-col items-center py-6"
            >
              <div className="w-full shadow-xl drop-shadow-xl bg-opacity-40 backdrop-blur-lg h-[230px] rounded-xl p-4 bg-white text-black">
                <div className="flex items-center gap-4">
                  <div className="flex items-center -mt-1 justify-center w-10 h-10 rounded-full">
                    <FiUser className="text-4xl text-black" />
                  </div>
                  <div className="flex flex-col items-start">
                    <h3 className="text-lg font-semibold">{review.name}</h3>
                    <h5 className="text-gray-500 text-sm -mt-1 italic">
                      {review.state}
                    </h5>
                  </div>
                </div>
                <p className="text-center mt-2">{review.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .marquee {
          width: 100%;
          overflow: hidden;
        }
        .marquee-content {
          display: flex;
          animation: marquee 44s linear infinite;
        }
        @keyframes marquee {
          0% {
            transform: translateX(50%);
          }
          100% {
            transform: translateX(-850%);
          }
        }
      `}</style>
    </div>
  );
};

export default ReviewSlider;
